<div class="license-container">
    <div class="license-container-header">
      <div class="document-container-subheader">
        <div class="document-container-subheader-details">
          <p class="license-title">{{documentType.name}}</p>
          <p class="license-description">Uploading these documents will enable the system to automatically attach them to form submission as required</p>
        </div>
        <div>
          <mat-button-toggle-group class="filters-toggle-group" [formControl]="filterForm.controls['documentSubTypeId']" >
            <mat-button-toggle class="filter-toggle-button" value="0" (change)="applyFilters()" [checked]="true">
              <mat-icon class="filter-toggle-button-icon" fontSet="material-icons-outlined">folder</mat-icon>
              <span class="filter-toggle-button-content">All</span>
            </mat-button-toggle>
            <mat-button-toggle class="filter-toggle-button" (change)="applyFilters()" *ngFor="let subType of documentType.documentSubTypes" [value]="subType.id">
              <mat-icon class="filter-toggle-button-icon" fontSet="material-icons-outlined">folder</mat-icon>
              <span class="filter-toggle-button-content">{{subType.name}}</span>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div class="license-filters">
        <div class="custom-form-field">
          <mat-form-field appearance="outline" class="custom-field sm no-hint">
            <input matInput placeholder="Search" [formControl]="filterForm.controls['searchText']" />
            <mat-icon class="filter-icon" matSuffix>search</mat-icon>
          </mat-form-field>
        </div>
        <div
          class="custom-form-field sm"
        >
          <app-mat-multi-select
            [options]="states"
            [control]="filterForm.controls['stateIds']"
            [innerLabel]="'States'"
            [size]="'sm'"
          />
        </div>
        <div
          class="custom-form-field sm"
        >
          <app-mat-multi-select
            [options]="products"
            [control]="filterForm.controls['productIds']"
            [innerLabel]="'Products'"
            [size]="'sm'"
          />
        </div>
        <div class="custom-form-field">
          <mat-form-field class="custom-field bg-white sm no-hint" appearance="outline">
            <input placeholder="Expiration Date" matInput [matDatepicker]="expDatePicker" (dateChange)="applyFilters()" [formControl]="filterForm.controls['expirationDate']">
            <mat-datepicker-toggle matSuffix [for]="expDatePicker">
              <mat-icon class="filter-icon" matDatepickerToggleIcon fontSet="material-icons-outlined">calendar_today</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #expDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="license-card-container">
      <app-provider-document-card
      [isLoadingLicenses]="isLoadingLicenses"
      [providerLicenses]="providerLicenses"
      [licenseActions]="licenseActions"
      >
      </app-provider-document-card>
    </div>
    <mat-paginator
      #paginator
      [selectConfig]="{panelClass: 'mat-paginator-select-options'}"
      [length]="this.totalCount"
      [pageIndex]="this.currentPage"
      [pageSize]="this.pageSize"
      [pageSizeOptions]="pageSettings.pageSizeOptions"
      (page)="handlePageEvent($event)"
      aria-label="Select page">
    </mat-paginator>
</div>
<div class="upload-license-container">
  <button mat-stroked-button color="primary" class="primary" (click)="openRightSidebar()">
    <mat-icon class="icon-btn">upload</mat-icon>
    Upload new Document
  </button>
</div>

<div class="license-sidebar">
  <div class="document-sidebar-header">
    <h4 class="document-sidebar-title">{{getSidebarTitle()}}</h4>
    <button class="close-license-sidebar" (click)="closeRightSidebar()"><mat-icon>close</mat-icon></button>
  </div>
  <div class="document-sidebar-body">
      <form class="animate-labels-form document-sidebar-content" [formGroup]="documentForm">
        <div class="document-select-subtype custom-form-field">
          <label id="radio-group-label" class="document-select-subtype-title">Select which type of document you are uploading:<span class="required">*</span></label>
          <mat-radio-group
              (change)="onChangeFormSubtype($event)"
              class="license-type-selection"
              aria-labelledby="radio-group-label"
              formControlName="documentSubTypeId"
              color="primary"
              >
              <mat-radio-button *ngFor="let subType of documentType.documentSubTypes" [value]="subType.id" >
                  {{getSubtypeName(subType)}}
              </mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="showApplicableStates()" class="custom-form-field">
          <label for="stateIds">Applicable States<span class="required">*</span></label>
          <app-mat-multi-select
            [options]="states"
            [control]="documentForm.controls['stateIds']"
            [innerLabel]="'Select an Option'"
            [requiredMessage]="'Select at least one applicable state'"
          />
        </div>
        <div *ngIf="showProducts()" class="custom-form-field">
          <label for="productIds">Products<span class="required">*</span></label>
          <app-mat-multi-select
            [options]="products"
            [control]="documentForm.controls['productIds']"
            [innerLabel]="'Select an Option'"
            [requiredMessage]="'Select at least one product'"
          />
        </div>
        <div *ngIf="showExpirationDate()" class="custom-form-field">
          <label for="expirationDate">Expiration Date<span class="required">*</span></label>
          <mat-form-field appearance="outline" class="custom-field">
            <input matInput [matDatepicker]="expirationDatePicker" name="expirationDate" formControlName="expirationDate">
            <mat-datepicker-toggle matIconSuffix [for]="expirationDatePicker">
              <mat-icon class="icon-btn" matDatepickerToggleIcon fontSet="material-icons-outlined">calendar_today</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #expirationDatePicker></mat-datepicker>
            <mat-error
            *ngIf="documentForm.controls['expirationDate'].errors?.['required']"
          >
            Please select Expiration Date
          </mat-error>
          <mat-error
            *ngIf="documentForm.controls['expirationDate'].errors?.['pastDate']"
          >
            Expiration Date can not be in the past
          </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group upload-document-container">
          <p class="upload-document-label">{{getFileUploadDescription()}}<span class="required">*</span></p>
          <app-upload-documents
          #documentFiles
          [files]="copyFilesToUpdate"
          (onFileRemove)="onLicenseCopyRemove($event)"
          (uploadingEvent)="uploadingLicenseCopyEventHandler($event)" >
          </app-upload-documents>
        </div>
        <div *ngIf="showFilingProofDropzone()" class="form-group upload-document-container" >
          <p class="upload-document-label">Upload proof of filing of the financial security requirement, if applicable:</p>
          <app-upload-documents
          #filingProofFiles
          [files]="filingProofFilesToUpdate"
          (onFileRemove)="onFilingProofRemove($event)"
          (uploadingEvent)="uploadingFilingProofEventHandler($event)" >
          </app-upload-documents>
        </div>
    </form>
  </div>
  <div class="document-sidebar-footer">
    <div class="document-sidebar-footer-buttons">
      <button mat-stroked-button (click)="closeRightSidebar()" [disabled]="isLoading" class="document-sidebar-footer-button-cancel">
        Cancel
      </button>
      <button mat-flat-button
        color="primary"
        type="button"
        (click)="onSubmit()"
        [disabled]="isLoading || documentForm.invalid" >
        Add
      </button>
    </div>
  </div>
</div>
